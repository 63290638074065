// Copied from:
// apps/kreate-web/modules/kreate-components/components/PrimaryFileViewer/utils.ts

import { ContentType } from "kreate-common/modules/business-types";

export function getContentCategory(
  contentType: ContentType | null | undefined
) {
  if (!contentType) {
    return undefined;
  } else if (contentType.startsWith("image/")) {
    return "image";
  } else if (contentType.startsWith("video/")) {
    return "video";
  } else if (contentType === "text/html") {
    return "html";
  } else {
    return undefined;
  }
}
