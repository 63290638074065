import cx from "clsx";
import { FileHandle } from "kreate-common/modules/business-types";
import { blobIdToUrl } from "kreate-env/client";
import React from "react";

import { ImageViewer } from "../../../ImageViewer";

import styles from "./index.module.scss";

const COUNTDOWN_DURATION = 500;

type State =
  | "showing-thumbnail"
  | "counting-down"
  | "playing-video"
  | "pausing-video";

type Action = "mouse-enter" | "mouse-leave" | "countdown-reached";

// just a state machine
function reducer(state: State, action: Action): State {
  switch (state) {
    case "showing-thumbnail":
      switch (action) {
        case "mouse-enter":
          return "counting-down";
        default:
          return state;
      }
    case "counting-down":
      switch (action) {
        case "mouse-leave":
          return "showing-thumbnail";
        case "countdown-reached":
          return "playing-video";
        default:
          return state;
      }
    case "playing-video":
      switch (action) {
        case "mouse-leave":
          return "pausing-video";
        default:
          return state;
      }
    case "pausing-video":
      switch (action) {
        case "mouse-enter":
          return "playing-video";
        default:
          return state;
      }
  }
}

type Props = {
  className?: string;
  style?: React.CSSProperties;
  file: FileHandle | null | undefined;
  thumbnailFile: FileHandle | null | undefined;
  sizes: string | undefined;
  slotIcons?: React.ReactNode;
  objectFit: "cover" | "contain";
  fill: true;
};

export default function SecondaryVideoViewer({
  className,
  style,
  file,
  thumbnailFile,
  slotIcons,
  sizes,
  objectFit,
}: Props) {
  // just an optimization
  const [posterUrl, setPosterUrl] = React.useState<string>();

  const [state, dispatch] = React.useReducer(reducer, "showing-thumbnail");

  React.useEffect(() => {
    if (state !== "counting-down") return;

    const timeoutId = setTimeout(() => {
      dispatch("countdown-reached");
    }, COUNTDOWN_DURATION);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [state]);

  return (
    <div
      className={cx(styles.container, className)}
      style={style}
      onMouseEnter={() => dispatch("mouse-enter")}
      onMouseLeave={() => dispatch("mouse-leave")}
    >
      {file && (state === "playing-video" || state === "pausing-video") ? (
        <video
          style={
            state !== "playing-video" ? { display: "none" } : { objectFit }
          }
          className={styles.video}
          muted
          autoPlay
          loop
          preload="none"
          poster={
            thumbnailFile
              ? posterUrl || blobIdToUrl(thumbnailFile.blob.blobId)
              : undefined
          }
        >
          <source
            src={blobIdToUrl(file.blob.blobId)}
            type={file.blob.contentType}
          />
        </video>
      ) : undefined}
      {state !== "playing-video" ? (
        <>
          <ImageViewer
            style={{ backgroundColor: "transparent" }}
            value={thumbnailFile || file}
            sizing="fill"
            objectFit={objectFit}
            sizes={sizes}
            onLoadingComplete={(img) => setPosterUrl(img.src)}
            preventAnimation
          />
          {slotIcons ? (
            <div
              className={cx(
                styles.slotIcons,
                state === "counting-down" ? styles.countingDown : undefined
              )}
              style={
                {
                  "--countdown-duration": `${COUNTDOWN_DURATION}ms`,
                } as React.CSSProperties
              }
            >
              {slotIcons}
            </div>
          ) : undefined}
        </>
      ) : undefined}
    </div>
  );
}
