import cx from "clsx";
import { FileHandle } from "kreate-common/modules/business-types";
import React from "react";

import { ImageViewer } from "../../../ImageViewer";

import styles from "./index.module.scss";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  file: FileHandle | null | undefined;
  thumbnailFile: FileHandle | null | undefined;
  sizes: string | undefined;
  objectFit: "cover" | "contain";
  slotIcons?: React.ReactNode;
  fill: true;
};

export default function SecondaryImageViewer({
  className,
  style,
  file,
  thumbnailFile,
  sizes,
  objectFit,
  slotIcons,
}: Props) {
  return (
    <div className={cx(styles.container, className)} style={style}>
      <ImageViewer
        style={{ backgroundColor: "transparent" }}
        value={file || thumbnailFile}
        sizing="fill"
        objectFit={objectFit}
        sizes={sizes}
      />
      {slotIcons ? (
        <div className={styles.slotIcons}>{slotIcons}</div>
      ) : undefined}
    </div>
  );
}
