import cx from "clsx";
import { FileHandle } from "kreate-common/modules/business-types";
import React from "react";

import Icons from "./components/Icons";
import SecondaryHtmlViewer from "./containers/SecondaryHtmlViewer";
import SecondaryImageViewer from "./containers/SecondaryImageViewer";
import SecondaryVideoViewer from "./containers/SecondaryVideoViewer";
import styles from "./index.module.scss";
import { getContentCategory } from "./utils";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  file: FileHandle | null | undefined;
  thumbnailFile: FileHandle | null | undefined;
  fill: true;
  sizes: string | undefined;
  objectFit?: "contain" | "cover";
  isSingleEdition?: boolean;
  reduceComplexity?: boolean;
};

/**
 * This component is supposed to be used for general cases. For more advanced
 * use cases, e.g. in `PageArtwork`, write your own components.
 */
export default function SecondaryFileViewer({
  className,
  style,
  file,
  thumbnailFile,
  sizes,
  objectFit = "contain",
  isSingleEdition,
  reduceComplexity,
}: Props) {
  if (reduceComplexity) {
    isSingleEdition = false;
  }

  return (
    <div className={cx(styles.container, className)} style={style}>
      {(() => {
        switch (getContentCategory(file?.blob.contentType)) {
          case "image":
            return (
              <SecondaryImageViewer
                file={file}
                thumbnailFile={thumbnailFile}
                sizes={sizes}
                objectFit={objectFit}
                slotIcons={
                  isSingleEdition ? <Icons.IconSingleEdition /> : undefined
                }
                fill
              />
            );
          case "video":
            return (
              <SecondaryVideoViewer
                file={file}
                thumbnailFile={thumbnailFile}
                sizes={sizes}
                slotIcons={
                  <>
                    <Icons.IconVideo />
                    {isSingleEdition ? <Icons.IconSingleEdition /> : undefined}
                  </>
                }
                objectFit={objectFit}
                fill
              />
            );
          case "html":
            return (
              <SecondaryHtmlViewer
                file={file}
                thumbnailFile={thumbnailFile}
                sizes={sizes}
                objectFit={objectFit}
                slotIcons={
                  <>
                    <Icons.IconHtml />
                    {isSingleEdition ? <Icons.IconSingleEdition /> : undefined}
                  </>
                }
                fill
              />
            );
        }
      })()}
    </div>
  );
}
