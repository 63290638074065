import cx from "clsx";
import { FileHandle } from "kreate-common/modules/business-types";
import { blobIdToUrl } from "kreate-env/client";
import React from "react";

import { ImageViewer } from "../../../ImageViewer";

import styles from "./index.module.scss";

const COUNTDOWN_DURATION = 500;

type Props = {
  className?: string;
  style?: React.CSSProperties;
  file: FileHandle | null | undefined;
  thumbnailFile: FileHandle | null | undefined;
  sizes: string | undefined;
  objectFit: "cover" | "contain";
  slotIcons?: React.ReactNode;
  fill: true;
};

export default function SecondaryHtmlViewer({
  className,
  style,
  file,
  thumbnailFile,
  sizes,
  objectFit,
  slotIcons,
}: Props) {
  const [fileMounted, setFileMounted] = React.useState(false);
  const [countingDown, setCountingDown] = React.useState(false);
  const [hovered, setHovered] = React.useState(false);

  const showingImage = !fileMounted || !hovered || !file;

  React.useEffect(() => {
    if (!fileMounted && hovered) {
      setCountingDown(true);
      const timeoutId = setTimeout(() => setFileMounted(true), 500);
      return () => {
        clearTimeout(timeoutId);
        setCountingDown(false);
      };
    }
  }, [fileMounted, hovered]);

  return (
    <div
      className={cx(styles.container, className)}
      style={style}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {showingImage ? (
        <ImageViewer
          style={{ backgroundColor: "transparent" }}
          value={thumbnailFile}
          sizing="fill"
          objectFit={objectFit}
          sizes={sizes}
          preventAnimation
        />
      ) : undefined}
      {fileMounted && file ? (
        <iframe
          className={styles.file}
          style={showingImage ? { display: "none" } : undefined}
          src={blobIdToUrl(file.blob.blobId)}
        />
      ) : undefined}
      {slotIcons && showingImage ? (
        <div
          className={cx(
            styles.slotIcons,
            countingDown ? styles.countingDown : undefined
          )}
          style={
            {
              "--countdown-duration": `${COUNTDOWN_DURATION}ms`,
            } as React.CSSProperties
          }
        >
          {slotIcons}
        </div>
      ) : undefined}
    </div>
  );
}
