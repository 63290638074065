import cx from "clsx";
import {
  formatError,
  getCauses,
  useDisplayableError,
} from "kreate-common/modules/displayable-error";
import Divider from "kreate-common/modules/kreate-ui/components/Divider";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import * as React from "react";
import { MdOutlineAdd, MdOutlineRemove } from "react-icons/md";

import styles from "./index.module.scss";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  error: unknown;
  defaultTitle?: string;
};

export default function ErrorViewer({
  className,
  style,
  error,
  defaultTitle,
}: Props) {
  const [showCausedBy, setShowCausedBy] = React.useState(false);
  const displayableError = useDisplayableError(error, defaultTitle);
  const { title, description } = displayableError || {};
  const causes = getCauses(displayableError);

  return (
    <div className={cx(styles.container, className)} style={style}>
      <div className={styles.title} style={{ fontWeight: "600" }}>
        {title}
      </div>
      <div style={{ marginTop: "16px" }}>{description}</div>
      <Divider.Horizontal color="none" className={styles.divider} />
      <Flex.Row justifyContent="space-between" style={{ marginTop: "24px" }}>
        <div className={styles.title} style={{ fontWeight: "500" }}>
          Caused By
        </div>
        <button
          className={styles.button}
          onClick={() => setShowCausedBy((showCausedBy) => !showCausedBy)}
        >
          {showCausedBy ? (
            <MdOutlineRemove size="24px" />
          ) : (
            <MdOutlineAdd size="24px" />
          )}
        </button>
      </Flex.Row>
      {showCausedBy ? (
        <Flex.Col gap="12px" className={styles.causesContainer}>
          {causes.map((cause, index) => (
            <div key={index} style={{ whiteSpace: "pre-wrap" }}>
              {formatError(cause)}
            </div>
          ))}
        </Flex.Col>
      ) : null}
    </div>
  );
}
